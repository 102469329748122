.privacy p {
  margin-left: 15px;
}

.privacy ul li {
  list-style-type: disc;
  margin-left: 30px;
}

.privacy span {
  display: block;
  margin-bottom: 20px;
}
